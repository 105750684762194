import AnchorInfoModel from '@/compnents/anchorInfo/model.ts';
import { type FormInstance, message } from 'antd';
import { action, observable, set, toJS, values } from 'mobx';
import type { BaseData, FilterTreeSelectItem } from '../../../../utils';
import { extractValuesByKey, request, transformKeys } from '../../../../utils';
import type Model from '../model';
import type { Area, PlatformSysBusinessCardVO } from '../type';
import ChangeBrokerModel from './changeBrokerModel';
export default class EditorModel {
  constructor(public mainModel?: Model) {
    this.getCityList();
    this.getRoleList();
  }

  @observable anchorInfoModalStore: AnchorInfoModel = new AnchorInfoModel(); //主播信息弹框
  @observable public changeBrokerModalStore: ChangeBrokerModel = new ChangeBrokerModel(this);

  @observable public visible = false;

  @observable public saveLoading = false;

  @observable public cityOption: any = [];

  @observable public roleList: any = [];
  @observable public type = 'add';
  @observable public adminMobile: string;
  @observable public adminId: number;
  @observable public adminNickName: string;
  @observable public userAvatar: string;
  @observable public userId: number;
  @observable public userMobile: string;
  @observable public userNickName: string;

  public ref: FormInstance;

  public selectRef: any;

  private preSelectAll = false;

  private flatCites = null; // 树形城市结构所有城市，展平为数组，包括新增全国

  public _transformValues = (list): any => {
    list?.forEach((item) => {
      item.value = String(item.value);
      if (item.children && Array.isArray(item.children)) {
        this._transformValues(item.children);
      }
    });
    return list;
  };
  @action public onOpen = (type?: 'add' | 'edit', row?: PlatformSysBusinessCardVO) => {
    if (type) {
      this.type = type;
    }
    this.visible = true;
    if (this.type === 'edit') {
      const {
        cityList,
        platformRoleVOList,
        adminMobile,
        adminId,
        adminNickName,
        userAvatar,
        userId,
        userMobile,
        userNickName,
      } = row;
      this.adminMobile = adminMobile;
      this.adminId = adminId;
      this.adminNickName = adminNickName;
      this.userAvatar = userAvatar;
      this.userId = userId;
      this.userMobile = userMobile;
      this.userNickName = userNickName;
      let currentCity = this._transformValues(
        transformKeys(cityList, { id: 'value', name: 'label', childrenList: 'children' }),
      );
      if (currentCity && this.flatTree(currentCity).length === this.flatCites.length - 1) {
        this.preSelectAll = true;
        currentCity = this.flatCites;
      }

      const status = row.type === 1 ? { value: 1, label: '服务雇主' } : { value: 0, label: '服务主播' };

      const currentRoleVOList = transformKeys(platformRoleVOList, { userRole: 'value', userRoleName: 'label' });

      const keyWord = row.keyWord ? (JSON.parse(row.keyWord)?.length ? JSON.parse(row.keyWord) : null) : null;

      const accountStatus = row.accountStatus === 1 ? 0 : 1;

      setTimeout(() => {
        this.ref?.setFieldsValue({
          ...row,
          keyWord,
          accountStatus,
          cityList: currentCity,
          platformRoleVOList: currentRoleVOList,
          type: status,
        });
      });
    }
  };

  @action public delete = (type: string) => {
    if (type === 'user') {
      this.userAvatar = null;
      this.userId = null;
      this.userMobile = null;
      this.userNickName = null;
    }
    if (type === 'admin') {
      this.adminMobile = null;
      this.adminId = null;
      this.adminNickName = null;
    }
    const fieldsToClear: Record<'user' | 'admin', (keyof EditorModel)[]> = {
      user: ['userAvatar', 'userId', 'userMobile', 'userNickName'],
      admin: ['adminMobile', 'adminId', 'adminNickName'],
    };

    const fields = fieldsToClear[type];
    if (fields) {
      fields.forEach((field) => {
        this[field] = null; // 不需要再使用 `as any`
      });
    }
    // 清除表单字段
    this.ref?.resetFields(fields.map((field) => field as string));
  };

  @action public onCancel = () => {
    this.visible = false;
    this.ref.resetFields();
  };

  public setRef = (ref: FormInstance) => {
    this.ref = ref;
  };
  @action public changeCityIds = (ids: string[]) => {
    const selectedItems = this.filterTree(toJS(this.cityOption), new Set(ids));

    const isSelectAll = [
      ids.includes('0') && ids[ids.length - 1] === '0',
      ids.length === this.flatCites.length - 1 && !this.preSelectAll,
    ];

    if (isSelectAll.indexOf(true) != -1) {
      this.ref.setFieldsValue({ cityList: this.flatCites });
      this.preSelectAll = true;
    } else {
      if (this.preSelectAll) {
        if (ids.includes('0')) {
          this.ref.setFieldsValue({ cityList: selectedItems.slice(1) });
          this.preSelectAll = false;
        } else {
          this.ref.setFieldsValue({ cityList: [] });
          this.preSelectAll = false;
        }
      } else {
        this.ref.setFieldsValue({ cityList: selectedItems });
        this.preSelectAll = false;
      }
    }
  };

  // 展平城市树
  private flatTree = (list) => {
    const result = [];
    const queue = [...list];
    while (queue.length) {
      const node = queue.shift();
      if (node.children) {
        for (let i = 0; i < node.children.length; i++) {
          queue.push(node.children[i]);
        }
      }
      if (node.children && node.children.length > 0) {
        continue;
      }
      result.push({ value: node.value, label: node.label });
    }
    return result;
  };

  // 过滤树结构中未选中城市
  @action public filterTree = (list, ids: Set<string>, count = 0) => {
    const result = [];
    const filterNode = (node) => {
      if (ids.has(String(node.value))) {
        result.push({
          value: String(node.value),
          label: node.label,
        });
      }
      if (node.children && node.children.length > 0) {
        node.children.forEach((item) => {
          filterNode(item);
        });
      }
    };
    list.forEach((item) => {
      filterNode(item);
    });
    return result;
  };

  @action public getRoleList = () => {
    request<BaseData<any>>({
      url: '/resource/role/page',
      method: 'post',
      data: {
        pageNum: 1,
        pageSize: 1000,
        sysType: 4,
      },
    }).then((res) => {
      if (res.data.list) {
        this.roleList = transformKeys(res.data.list, { roleId: 'value', roleName: 'label' });
      }
    });
  };

  @action public getCityList = async () => {
    request<BaseData<any>>({
      url: '/baseinfo/area/province/city/list',
      method: 'get',
    }).then((res) => {
      this.cityOption = [
        { value: '0', label: '全国' },
        ...transformKeys(res.data, { id: 'value', name: 'label', childrenList: 'children' }),
      ];
      this.flatCites = this.flatTree(this.cityOption);
    });
  };

  @action public changePlatform = (value) => {
    this.ref.setFieldsValue({
      platformRoleVOList: transformKeys(value, { userRole: 'value', userRoleName: 'label' }),
    });
  };

  @action public onFinish = async () => {
    if (this.saveLoading) {
      return;
    }

    const values = await this.ref.getFieldsValue(true);

    const postParams = {
      ...values,
    };

    if (!(values.adminId || values.userId)) {
      return message.error('请选择一个经纪人用户');
    }

    console.log(values.adminId, values.userId, 'ooooo');

    this.saveLoading = true;

    const apiUrl =
      this.type === 'add' ? '/pbb/platform/broker/businessCard/add' : '/pbb/platform/broker/businessCard/edit';

    try {
      let postList = values.cityList;
      if (values.cityList?.[0].label === '全国') {
        postList = values.cityList.slice(1);
      }
      if (this.type === 'add' && values.adminId) {
        postParams.adminId = values.adminId?.value;
      }
      if (this.type === 'add' && values.userId) {
        postParams.userId = values.userId?.value;
      }

      postParams.shareFreeSwitch = values.shareFreeSwitch ? 1 : 0;
      postParams.canWork = values.canWork ? 1 : 0;
      if (values.type) {
        postParams.type = values.type.value;
      }

      const accountStatus = values.accountStatus === 1 ? 0 : 1;

      return request<BaseData<any>>({
        url: apiUrl,
        method: 'POST',
        data: {
          ...postParams,
          accountStatus,
          keyWord: JSON.stringify(values.keyWord),
          cityList: transformKeys(postList, { value: 'id', label: 'name' }),
          type: 0,
        },
      }).then(() => {
        this.onCancel();
        this.mainModel.mainSubStructureModel?.grid?.onQuery();
        message.success(this.type === 'add' ? '添加成功' : '编辑成功');
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.saveLoading = false;
    }
  };
}

import AnchorInfoModal from '@/compnents/anchorInfo';
import { SearchListStructure } from '@/utils';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { MainSubStructure } from '../../../utils/egGrid';
import Editor from './modal/editor';
import EstimatedRevenue from './modal/estimatedRevenue';
import Model from './model';

export default observer(() => {
  const store = useMemo(() => new Model(), []);
  const { mainSubStructureModel, editorModel, anchorInfoModalStore, estimatedRevenueStore } = store;

  return (
    <div className="h-screen">
      <SearchListStructure store={mainSubStructureModel} />
      <Editor store={editorModel} />
      <AnchorInfoModal store={anchorInfoModalStore} />
      <EstimatedRevenue store={estimatedRevenueStore} />
    </div>
  );
});

import { ASSETS } from '@/assets';
import AnchorInfoModal from '@/compnents/anchorInfo';
import Store from '@/pages/employment/store';
import { accountTypeDict } from '@/pages/positionManagement/components/add/dict';
import { type BaseData, EpUpload, multiple, request, requireSource } from '@/utils';
import compressImgOrVideo from '@/utils/compress';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import type { InputRef, SelectProps } from 'antd';
import {
  Avatar,
  Button,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Select,
  Switch,
  Tag,
  TreeSelect,
  Upload,
  message,
} from 'antd';
import type { RcFile } from 'antd/es/upload';
import { observer } from 'mobx-react';
import { type ChangeEvent, useEffect, useRef, useState } from 'react';
import type React from 'react';
import { SearchSelect } from '../utils';
import ChangeBroker from './changeBroker';
import type Model from './editorModel';

interface miniData {
  data: {
    keyWord?: string;
  };
  label: string;
  value: any;
}

// 新增或编辑需求配置信息

const Editor = ({ store }: { store: Model }) => {
  const [selectOption, setSelectOption] = useState<{ keyWord?: string }>({});
  useEffect(() => {
    selectOption;
  }, [selectOption]);
  return (
    <Modal
      centered={true}
      open={store.visible}
      onOk={() => {
        store.ref?.submit();
      }}
      maskClosable={false}
      onCancel={store.onCancel}
      confirmLoading={store.saveLoading}
      title={store.type === 'add' ? '新增经纪人' : '编辑经纪人'}
    >
      <Form
        ref={store.setRef}
        onFinish={store.onFinish}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 16 }}
        initialValues={{
          canWork: store.type === 'add' ? 1 : store.ref,
          accountStatus: store.type === 'add' ? 1 : store.ref,
        }}
      >
        <div className="flex justify-end">
          {store.type === 'add' ? (
            <div className="mr-[360px]">
              <span className="text-error mr-1">*</span>选择经纪人用户：
            </div>
          ) : (
            <div className="mr-60">
              <span className="text-error mr-1">*</span>经纪人信息：
            </div>
          )}

          {store.type === 'edit' && (
            <div className="mr-10">
              <Button
                type="link"
                onClick={() => store.changeBrokerModalStore.onShow()}
              >
                重新选择用户
              </Button>
            </div>
          )}
        </div>

        {store.type === 'add' && (
          <div className="ml-16">
            <Form.Item
              label="选择内部用户"
              name="adminId"
              labelCol={{ span: 8 }} // 使用花括号包裹 span 属性
            >
              <SearchSelect
                placeholder={'输入用户昵称/手机号/ID搜索选择'}
                api="/pbb/platform/sys/user/list/page"
                className={'!w-54'}
                selectKeyword="keyWord"
                name="nickName"
                nameValue="id"
              />
            </Form.Item>
            <Form.Item
              label="选择小程序用户"
              name="userId"
              labelCol={{ span: 8 }}
            >
              <SearchSelect
                placeholder={'输入用户昵称/手机号/ID搜索选择'}
                className={'!w-54'}
                api="/pbb/platform/anchor/list"
                selectKeyword="name"
                name="nickName"
                nameValue="id"
                keyWord="avatar"
                type="mini"
                optionRender={(item: miniData) => {
                  return (
                    <div className="flex">
                      <img
                        src={item.data.keyWord}
                        alt=" "
                        className="w-8 h-8 rounded-full mr-2"
                      />
                      <span>{item.label}</span>
                    </div>
                  );
                }}
                onChange={(option) => {
                  setSelectOption(option);
                }}
                labelRender={(item: miniData) => {
                  const keyWord = selectOption.keyWord;
                  return (
                    <div className="flex">
                      <img
                        src={keyWord}
                        alt=" "
                        className="w-5 h-5 rounded-full mr-2 mt-1"
                      />
                      <span>{item.label}</span>
                    </div>
                  );
                }}
              />
            </Form.Item>
          </div>
        )}
        {store.type === 'edit' && (
          <div className="ml-16 text-12">
            <div className="flex ml-3">
              <div className="flex-shrink-0">内部用户：</div>
              {store.adminId && (
                <div className="flex w-full justify-between items-center">
                  <div className="text-12 leading-[18px] ml-2">
                    <div>用户昵称：{store.adminNickName}</div>
                    <div>ID：{store.adminId}</div>
                    <div>手机号：{store.adminMobile}</div>
                  </div>
                  <Button
                    type="link"
                    className="mr-10"
                    onClick={() => {
                      store.delete('admin');
                    }}
                  >
                    删除
                  </Button>
                </div>
              )}
            </div>
            <div className="flex mt-4 mb-4">
              <div className="flex-shrink-0"> 小程序用户：</div>
              {store.userId && (
                <div className="flex w-full justify-between items-center">
                  <div
                    className="flex items-center cursor-pointer h-full gap-2"
                    onClick={() => {
                      //弹出主播信息资料
                      store.anchorInfoModalStore.onShow(store.userId.toString());
                    }}
                  >
                    {store.userAvatar && (
                      <div className="relative">
                        <div className="rounded-[50%] w-13 h-13 m-0 p-0 overflow-hidden">
                          <img
                            src={store.userAvatar}
                            className="w-full h-full"
                            alt=""
                          />
                        </div>
                      </div>
                    )}
                    <div className="text-12 leading-[18px] ml-2">
                      <div className="flex items-center">
                        用户昵称：{store.userNickName}
                        <img
                          src={requireSource('/common/idcard.png')}
                          alt={''}
                          className={'w-4 h-4 ml-1'}
                        />
                      </div>
                      <div>ID：{store.userId}</div>
                      <div>手机号：{store.userMobile}</div>
                    </div>
                  </div>
                  <Button
                    type="link"
                    className="mr-10"
                    onClick={() => {
                      store.delete('user');
                    }}
                  >
                    删除
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
        <Form.Item
          name="cardName"
          label="对外展示姓名"
          rules={[
            {
              required: true,
              message: '请输入对外展示姓名',
            },
            {
              max: 20,
              message: '不能超过20个字符',
            },
          ]}
        >
          <Input
            autoComplete="off"
            placeholder="请输入对外展示姓名"
          />
        </Form.Item>
        <Form.Item
          name="cityList"
          label="服务城市"
          rules={[
            {
              required: true,
              message: '请选择服务城市',
            },
          ]}
        >
          <TreeSelect
            filterTreeNode={(inputValue: string, treeNode: any) => {
              return treeNode.label?.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1;
            }}
            allowClear={true}
            treeData={store.cityOption}
            maxTagCount={10}
            placeholder="请选择服务城市"
            multiple={true}
            treeCheckable={true}
            showSearch={true}
            onChange={(ids: string[]) => {
              store.changeCityIds(ids);
            }}
          />
        </Form.Item>
        <Form.Item
          name="amount"
          label="服务主播价格"
          rules={[
            {
              required: true,
              message: '请输入服务主播价格',
            },
          ]}
        >
          <InputNumber
            style={{ width: 315 }}
            min={0}
            precision={2}
            placeholder="请输入服务主播价格"
          />
        </Form.Item>
        <Form.Item
          label="分享免费"
          name="shareFreeSwitch"
          initialValue={0}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label="承接流量"
          name="canWork"
          rules={[
            {
              required: true,
            },
          ]}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <ImageUpload
          name="mainUrl"
          label="形象照"
          message="请上传形象照"
          size={6}
        />
        <KeyWordTags
          name="keyWord"
          label="关键词"
          message="请输入关键词"
        />
        <Form.Item
          name="briefIntroduction"
          label="简介"
          rules={[
            {
              required: true,
              message: '请输入简介',
            },
            {
              max: 100,
              message: '不能超过100个字符',
            },
          ]}
        >
          <Input.TextArea
            maxLength={100}
            showCount={true}
          />
        </Form.Item>
        <ImageUpload
          name="wechatCode"
          label="企微二维码"
          message="请上传企微二维码"
          size={2}
        />

        <Form.Item
          label="状态"
          name="accountStatus"
          rules={[
            {
              required: true,
            },
          ]}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </Form>
      <AnchorInfoModal store={store.anchorInfoModalStore} />
      <ChangeBroker store={store.changeBrokerModalStore} />
    </Modal>
  );
};

const ImageUpload = (props: {
  readonly name: string;
  readonly label: string;
  readonly message: string;
  readonly size: number;
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <Form.Item
      noStyle={true}
      shouldUpdate={(prevValues, nextValues) => {
        return prevValues[props.name] !== nextValues[props.name];
      }}
    >
      {({ getFieldValue, setFieldValue }) => {
        const imageUrl = getFieldValue(props.name);
        const customRequest = async (options: any) => {
          console.log(options.file, 'options.file');

          setLoading(true);
          const unit = options.file.name.split(',');
          const file: any = await compressImgOrVideo(options.file);
          if (file === false) {
            return;
          }
          const url = await EpUpload.singleUpload({
            attachmentType: 1,
            module: 'opus',
            file: file,
          });
          setFieldValue(props.name, url);
          setLoading(false);
        };

        const beforeUpload = (file: RcFile) => {
          if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            message.error('图片格式只能为jpg、jpeg、png');
            return false;
          }
          // 限制文件大小，例如限制为 2MB
          const isLtSzie = file.size / 1024 / 1024 < (props.size || 6);
          if (!isLtSzie) {
            message.error(`图片需要小于${props.size}MB!`);
            return false;
          }
          return true;
        };

        return (
          <Form.Item
            label={props.label}
            name={props.name}
            getValueFromEvent={(e) => imageUrl}
            validateTrigger={false}
            required={true}
          >
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={customRequest}
              beforeUpload={beforeUpload}
              accept=".jpg,.jpeg,.png,.webp"
            >
              {imageUrl ? (
                <Image
                  src={imageUrl}
                  preview={false}
                  style={{
                    width: '80px',
                    height: '80px',
                    objectFit: 'cover',
                  }}
                />
              ) : (
                <button
                  style={{
                    border: 0,
                    background: 'none',
                  }}
                  type="button"
                >
                  {loading ? <LoadingOutlined /> : <PlusOutlined />}
                  <div className="mt-2">上传</div>
                </button>
              )}
            </Upload>
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

const KeyWordTags = (props: { readonly name: string; readonly label: string; readonly message?: string }) => {
  const inputRef = useRef<InputRef>(null);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');

  return (
    <Form.Item
      noStyle={true}
      shouldUpdate={(prevValues, nextValues) => {
        return prevValues[props.name] !== nextValues[props.name];
      }}
    >
      {({ getFieldValue, setFieldValue }) => {
        // getValueFromEvent 要返回原始数据 这里返回的是 tags
        const tags: string[] = getFieldValue(props.name);
        const tagsArr: string[] = tags || [];
        const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
          setInputValue(e.target.value);
        };

        const handleInputConfirm = () => {
          if (inputValue && !tagsArr.includes(inputValue)) {
            setFieldValue(props.name, [...tagsArr, inputValue]);
          }
          setInputVisible(false);
          setInputValue('');
        };

        const showInput = () => {
          if (tagsArr.length < 2) {
            setInputVisible(true);
          } else {
            message.error('最多只能添加2个标签');
          }
        };
        const handleClose = (e: React.MouseEvent<HTMLElement>, removedTag: string) => {
          e.preventDefault();
          setFieldValue(
            props.name,
            tagsArr.filter((tag) => tag !== removedTag),
          );
        };

        return (
          <Form.Item
            label={props.label}
            name={props.name}
            getValueFromEvent={() => tags}
            rules={[
              {
                required: true,
                message: props.message,
                validateTrigger: 'blur',
                validator: () => {
                  return new Promise((resolve, reject) => {
                    console.log('validator', tagsArr.length);
                    if (tagsArr.length > 0) {
                      resolve('');
                    } else {
                      reject('请输入2个标签');
                    }
                  });
                },
              },
            ]}
          >
            <div className="flex items-center ">
              {tagsArr?.map((item, index) => (
                <Tag
                  key={`${index.toString()}`}
                  closable={true}
                  style={{ userSelect: 'none' }}
                  onClose={(e) => handleClose(e, item)}
                >
                  <span>{item}</span>
                </Tag>
              ))}

              {inputVisible ? (
                <Input
                  className="w-24"
                  ref={inputRef}
                  type="text"
                  size="small"
                  value={inputValue}
                  maxLength={6}
                  onChange={handleInputChange}
                  onBlur={handleInputConfirm}
                  onPressEnter={handleInputConfirm}
                />
              ) : (
                <Tag
                  icon={<PlusOutlined />}
                  onClick={showInput}
                >
                  自定义标签
                </Tag>
              )}
            </div>
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default observer(Editor);
